<template>
  <v-layout
    :style="{
      position: isPc ? 'relative' : undefined,
      flexDirection: isPc ? 'column' : 'row',
    }"
  >
    <JwGnbHeader v-if="isPc" />
    <v-navigation-drawer
      v-model="sideNavWrap.show"
      name="left"
      touchless
      :absolute="isPc"
      :style="{
        width: !isMobile ? '375px' : '100%',
        height: isPc ? 'auto' : 'calc(100vh - 75px)',
        bottom: isPc ? '155px' : 'var(--v-layout-bottom)',
        left: isPc ? '50%' : undefined,
        marginLeft: isPc ? '-450px' : undefined,
        paddingTop: isPc ? `50px` : undefined,
      }"
    >
      <v-toolbar color="background" absolute class="jw-app-header jw-app-header--room">
        <v-toolbar-title> 더보기 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="24" @click="router.push('/pub/more/pushNoti')">
          <v-icon size="20">jwIcons:svg/bell</v-icon>
        </v-btn>
        <v-btn size="24" class="ml-4" @click="router.push('/pub/more/notice')">
          <v-icon size="24">jwIcons:svg/bullhorn</v-icon>
        </v-btn>
        <v-btn size="24" class="ml-4 mr-5" @click="router.push('/pub/more/setting')">
          <v-icon size="24">jwIcons:svg/gear</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container :fluid="!isPc" class="pa-5 pt-4" style="padding-top: 78px !important">
        <div class="jw-more-top">
          <div class="jw-more-top--presentation">
            <div class="title">예수길벗교회<small>(이호훈목사)</small></div>
            <div class="items">
              <div class="items__item">
                <VImg src="~/assets/images/sample_moretop_01.png" width="100%" cover class="jw-card-video__thumbnail"> </VImg>
                <span class="up">UP</span>
                <div class="item-cover">
                  <div class="item-cover__title">금주 설교</div>
                  <div class="progress">
                    <span class="bar" style="width: 60%"></span>
                  </div>
                </div>
              </div>
              <div class="items__item" @click="router.push('/pub/more/weekly')">
                <VImg src="~/assets/images/sample_moretop_02.png" width="100%" cover class="jw-card-video__thumbnail"> </VImg>
                <span class="up">UP</span>
                <div class="item-cover">
                  <div class="item-cover__title">주보</div>
                </div>
              </div>
            </div>
          </div>
          <div class="jw-more-top--detail">
            <div class="title">어버이 주일</div>
            <VBtn variant="text">
              설교본문
              <VIcon size="14" color="#ffffff">jwIcons:svg/chevron-right-white</VIcon>
            </VBtn>
            <div class="text">요 10:11~11:32</div>
            <VBtn variant="flat" height="30" rounded @click="router.push('/pub/more/offering')">온라인 헌금</VBtn>
          </div>
          <VBtn icon size="24" color="transparent" class="jw-more-top--share">
            <VIcon size="24" color="#ffffff">jwIcons:svg/more-horizon</VIcon>
          </VBtn>
        </div>

        <v-list class="jw-more--links mt-5">
          <v-list-item v-for="(item, index) in moreLinks" :key="index" :class="{ new: item.isNew }">
            <VBtn variant="text" @click="router.push(`/pub/more/${item.target}`)">
              <VIcon size="36">{{ item.image }}</VIcon>
              <span class="text">{{ item.text }}</span>
            </VBtn>
          </v-list-item>
        </v-list>

        <!-- 배너 시작 -->
        <div class="jw-container--full mb-5 mt-5">
          <swiper v-bind="defaultSwiperOption" class="jw-swiper">
            <swiper-slide v-for="(slide, index) in bannerSlide" :key="`bannerSlide-${index}`">
              <div class="jw-banner-card jw-banner-card--wrap" @click="onClickBanner(slide.target)">
                <VImg :src="slide.image" width="100%" aspect-ratio="100/19" cover>
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
                    </div>
                  </template>
                </VImg>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <!--/ 배너 끝 -->

        <v-list class="jw-link-border mt-5">
          <!-- AS-IS
      <v-list-item v-for="(item, index) in links" :key="index">
        <v-btn height="50" variant="text">
          <span class="text">{{ item.text }}</span>
          <VIcon size="24" color="#000000">jwIcons:svg/chevron-right-gray-thin</VIcon>
        </v-btn>
      </v-list-item>
      -->
          <!-- 
        마크업은 아래처럼 list-item에 링크 바로 할게요. 스타일만 다시 부탁드려요.(커서 이벤트도 pointer로)
        패딩이 따로 잡혀있는지 .px-2가 안먹네요 ㅎㅎ
      -->
          <v-list-item v-for="(item, index) in links" :key="index" class="px-2" @click="() => console.log(item.text)">
            <span class="text">{{ item.text }}</span>
            <v-spacer></v-spacer>
            <VIcon size="24" color="#000000">jwIcons:svg/chevron-right-gray-thin</VIcon>
          </v-list-item>
        </v-list>

        <VBtn icon color="transparent" class="jw-more-question">
          <img src="~/assets/images/more-question.svg" />
        </VBtn>
      </v-container>
    </v-navigation-drawer>
    <VMain
      name="main"
      :style="{
        '--v-layout-top': !isPc ? 0 : '50px',
        '--v-layout-left': !isPc && isTablet && !isMobile ? '375px' : 0,
        '--v-layout-bottom': isMobile ? '75px' : 0,
        backgroundColor: isPc ? '#FFFCF8' : undefined,
        overflow: sideNavWrap.show && isMobile ? hidden : undefined,
      }"
    >
      <v-container
        v-resize="onResize"
        :fluid="!isPc"
        class="jw-more--right"
        :style="{ padding: 0, paddingLeft: isPc ? '375px' : 0, minHeight: isPc ? `calc(100vh - 210px)` : undefined, paddingTop: isPc ? '50px' : 0 }"
      >
        <div ref="jwMainContainer">
          <!-- {{ `isPc:${isPc}, isTablet:${isTablet}, isMobile:${isMobile}` }} -->
          <slot />
        </div>
      </v-container>
    </VMain>
    <JwFooter v-if="showMainFooter" />
    <JwBottomNav
      v-if="!isPc"
      :style="{
        width: isMobile ? '100%' : '375px',
        left: isMobile ? 0 : undefined,
        paddingLeft: isMobile ? 0 : undefined,
        zIndex: 1007,
        borderRight: !isMobile ? '1px solid rgba(var(--v-border-color), var(--v-border-opacity))' : undefined,
      }"
    />
  </v-layout>
</template>
<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import PageHeader from "@/components/common/PageHeader.vue";
import JwGnbHeader from "@/components/common/JwGnbHeader.vue";
import JwBottomNav from "@/components/common/JwBottomNav.vue";
import JwFooter from "@/components/common/JwFooter.vue";
import { useDisplay } from "vuetify";
import sampleImage from "~/assets/images/sample_mor_banner1.png"; // 샘플 이미지 임시로 넣었어요
const router = useRouter();
const route = useRoute();
const { smAndDown, xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
const isMobile = computed(() => smAndDown.value);
const isTablet = computed(() => !isPc.value && !isMobile.value);

const jwMainContainer = ref(null);

const sideNavWrap = reactive({
  width: "375px",
  height: "auto",
  show: true,
});

onMounted(() => {
  onResize();
});
onUpdated(() => {
  onResize();
});
watch(() => route.path, onResize);

async function onResize() {
  await nextTick();
  sideNavWrap.width = !isMobile.value ? "375px" : "100%";
  sideNavWrap.height = jwMainContainer.value?.getBoundingClientRect().height + 50;
  sideNavWrap.show = !isMobile.value || route.path.endsWith("/more/");
}

const showMainNav = computed(() => {
  if (["section-id", "shareDetail"].includes(route.name)) {
    return false;
  } else {
    return !isPc.value;
  }
});

const showMainFooter = computed(() => {
  if (["section-id", "shareDetail"].includes(route.name)) {
    return false;
  } else {
    return isPc.value ? true : false;
  }
});

const defaultSwiperOption = reactive({
  slidesPerView: "auto",
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  spaceBetween: 10,
});

const onClickBanner = (targetPath) => {};

// 여기서 밑에는 Swiper slide 데이터 입니다.
// 배너 슬라이드 데이터
const bannerSlide = reactive([
  {
    title: "[테스트] 아주아주 긴 제목 테스트 - 새로운 봄. With Mobile",
    subtitle: "2022년 5월호 - vol 24",
    image: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 5",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 4월호 - vol 23",
    image: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 4",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 3월호 - vol 22",
    image: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 3",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 2월호 - vol 21",
    image: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 2",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 1월호 - vol 20",
    image: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 1",
  },
]);

const moreLinks = reactive([
  {
    image: "jwIcons:svg/more-edu",
    text: "교육",
    isNew: true,
    target: "",
  },
  {
    image: "jwIcons:svg/more-sermon",
    text: "설교",
    isNew: false,
    target: "sermon",
  },
  {
    image: "jwIcons:svg/more-worship",
    text: "예배",
    isNew: true,
    target: "thursday",
  },
  {
    image: "jwIcons:svg/more-pray",
    text: "기도",
    isNew: false,
    target: "",
  },
  {
    image: "jwIcons:svg/more-sponsor",
    text: "후원",
    isNew: false,
    target: "sponsor",
  },
  {
    image: "jwIcons:svg/more-magazine",
    text: "매거진",
    isNew: true,
    target: "",
  },
  {
    image: "jwIcons:svg/more-praise",
    text: "찬양",
    isNew: false,
    target: "",
  },
  {
    image: "jwIcons:svg/more-fellow",
    text: "동역",
    isNew: false,
    target: "fellow",
  },
  {
    image: "jwIcons:svg/more-school",
    text: "교회학교",
    isNew: false,
    target: "",
  },
]);

const links = reactive([
  {
    text: "예수동행일기 안내",
  },
  {
    text: "자주 묻는 질문",
  },
]);
</script>
